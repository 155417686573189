import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';

const Logout = ({ data, pageContext }) => {
  const { isAuthenticated, logout } = useAuth();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      logout();
    } else if (typeof window !== 'undefined') {
      window.location.href = '/';
    }
  }, [isAuthenticated, hasMounted]);

  return <></>;
};

export default Logout;